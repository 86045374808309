<template>
  <div>
    <div class="s1 container py-10">
      <div class="flex-container">
        <div class="even-space">
          <h1>Edit, share and review your podcast with PodJourney!</h1>
          <p>Welcome to PodJourney, the all-in-one podcast platform.</p>
          <a
            href="#"
            class="black-btn"
          >
            <img
              src="~/assets/images/play-icon.png"
              alt="temp image"
            >
            Let’s podcast
          </a>
          <a
            href="#"
            class="white-btn"
          >
            🚀 Explore more about
          </a>
        </div>
        <div class="even-space">
          <img
            src="~/assets/images/group-8150.png"
            alt="temp image"
          >
        </div>
      </div>
    </div>

    <div class="s2">
      <div class="container py-16">
        <div class="flex-container">
          <div class="even-space float-image">
            <img
              src="~/assets/images/labtop.png"
              alt="temp image"
            >
          </div>
          <div class="even-space">
            <h2>Starting a podcast?</h2>
            <p class="description">
              With Podpro, you can edit, share, and review your podcast all in one place. Our easy-to-use tools make it simple to create a professional-sounding podcast that you can share with the world.
            </p>
            <div class="flex-container">
              <img
                src="~/assets/images/checkmark-icon.png"
                alt="temp image"
              >
              <p class="checkpoint">
                Production
              </p>
              <img
                src="~/assets/images/checkmark-icon.png"
                alt="temp image"
              >
              <p class="checkpoint">
                Coaching
              </p>
              <img
                src="~/assets/images/checkmark-icon.png"
                alt="temp image"
              >
              <p class="checkpoint">
                Hosting
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="s3 container py-20">
      <h3>Used and trusted by</h3>
      <img
        class="desktop"
        src="~/assets/images/logos-list.png"
        alt="temp image"
      >
      <img
        class="mobile"
        src="~/assets/images/logos-mobile1.png"
        alt="temp image"
      >
      <img
        class="mobile"
        src="~/assets/images/logos-mobile2.png"
        alt="temp image"
      >
    </div>

    <div class="s4 container py-10">
      <div class="flex-container">
        <div class="even-space">
          <img
            src="~/assets/images/group-8151.png"
            alt="temp image"
          >
        </div>
        <div class="even-space">
          <h2>Get your work reviewed <br>by the best coaches!</h2>
          <p>PodPro is a coaching platform that allows podcasters to connect with certified podcast coaches to help perfect their craft and grow their podcast. Leveraging digital technology, PodJourney provides podcasters with a customized coaching experience that allows them access to an industry expert for one-on-one sessions.</p>
          <a
            href="#"
            class="black-btn"
          >
            Improve my podcast
          </a>
        </div>
      </div>
    </div>

    <div class="s5 container py-20">
      <div class="flex-container">
        <div class="title-holder">
          <h2>Production</h2>
          <p>Our production section allows you to insert any audio clips live as you record. Enhance your podcast by adding custom intro, ads, or any audio enhancements on the fly. Control looping, fading, and volume all with hotkeyed playback.</p>
        </div>
        <div class="sound-container">
          <div>
            <img
              src="~/assets/images/intro-sound.png"
              alt="temp image"
            >
          </div>
          <div>
            <img
              src="~/assets/images/drums-sound.png"
              alt="temp image"
            >
          </div>
          <div>
            <img
              src="~/assets/images/play-sound.png"
              alt="temp image"
            >
          </div>
          <div>
            <img
              src="~/assets/images/lo-fi-sound.png"
              alt="temp image"
            >
          </div>
          <div>
            <img
              src="~/assets/images/applause-sound.png"
              alt="temp image"
            >
          </div>
          <div>
            <img
              src="~/assets/images/add-sound.png"
              alt="temp image"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="s6">
      <div class="container py-20">
        <div class="flex-container">
          <div>
            <h2>There is no better toolkit for creators than this one</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
          </div>
          <div class="toolkit-mobile-container">
            <div class="toolkit">
              <button
                class="dropdown"
                @click="isHiddenQuality = !isHiddenQuality"
              >
                <div class="title-holder">
                  <img
                    src="~/assets/images/mic-icon.svg"
                    alt="temp image"
                  >
                  <p class="bold-title">
                    Better quality
                  </p>
                </div>
                <img
                  src="~/assets/images/dropdown-arrow.svg"
                  alt="temp image"
                >
              </button>
              <div
                v-if="!isHiddenQuality"
                class="panel"
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
              </div>
            </div>
            <div class="toolkit">
              <button
                class="dropdown"
                @click="isHiddenProduction = !isHiddenProduction"
              >
                <div class="title-holder">
                  <img
                    src="~/assets/images/note-icon.svg"
                    alt="temp image"
                  >
                  <p class="bold-title">
                    Production
                  </p>
                </div>
                <img
                  src="~/assets/images/dropdown-arrow.svg"
                  alt="temp image"
                >
              </button>
              <div
                v-if="!isHiddenProduction"
                class="panel"
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
              </div>
            </div>
            <div class="toolkit">
              <button
                class="dropdown"
                @click="isHiddenHosting = !isHiddenHosting"
              >
                <div class="title-holder">
                  <img
                    src="~/assets/images/graph-icon.svg"
                    alt="temp image"
                  >
                  <p class="bold-title">
                    Hosting
                  </p>
                </div>
                <img
                  src="~/assets/images/dropdown-arrow.svg"
                  alt="temp image"
                >
              </button>
              <div
                v-if="!isHiddenHosting"
                class="panel"
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
              </div>
            </div>
            <div class="toolkit">
              <button
                class="dropdown"
                @click="isHiddenCoaching = !isHiddenCoaching"
              >
                <div class="title-holder">
                  <img
                    src="~/assets/images/speak-icon.svg"
                    alt="temp image"
                  >
                  <p class="bold-title">
                    Coaching
                  </p>
                </div>
                <img
                  src="~/assets/images/dropdown-arrow.svg"
                  alt="temp image"
                >
              </button>
              <div
                v-if="!isHiddenCoaching"
                class="panel"
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
              </div>
            </div>
            <div class="toolkit">
              <button
                class="dropdown"
                @click="isHiddenMarketing = !isHiddenMarketing"
              >
                <div class="title-holder">
                  <img
                    src="~/assets/images/heart-icon.svg"
                    alt="temp image"
                  >
                  <p class="bold-title">
                    Marketing
                  </p>
                </div>
                <img
                  src="~/assets/images/dropdown-arrow.svg"
                  alt="temp image"
                >
              </button>
              <div
                v-if="!isHiddenMarketing"
                class="panel"
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
              </div>
            </div>
            <div class="toolkit">
              <button
                class="dropdown"
                @click="isHiddenMonetize = !isHiddenMonetize"
              >
                <div class="title-holder">
                  <img
                    src="~/assets/images/money-icon.svg"
                    alt="temp image"
                  >
                  <p class="bold-title">
                    Monetize
                  </p>
                </div>
                <img
                  src="~/assets/images/dropdown-arrow.svg"
                  alt="temp image"
                >
              </button>
              <div
                v-if="!isHiddenMonetize"
                class="panel"
              >
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
              </div>
            </div>
          </div>
          <div class="toolkit-flex-container">
            <div class="toolkit">
              <img
                src="~/assets/images/mic-icon.svg"
                alt="temp image"
              >
              <p class="bold-title">
                Better quality
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
            </div>
            <div class="toolkit">
              <img
                src="~/assets/images/note-icon.svg"
                alt="temp image"
              >
              <p class="bold-title">
                Production
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
            </div>
            <div class="toolkit">
              <img
                src="~/assets/images/graph-icon.svg"
                alt="temp image"
              >
              <p class="bold-title">
                Hosting
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
            </div>
            <div class="toolkit">
              <img
                src="~/assets/images/speak-icon.svg"
                alt="temp image"
              >
              <p class="bold-title">
                Coaching
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
            </div>
            <div class="toolkit">
              <img
                src="~/assets/images/heart-icon.svg"
                alt="temp image"
              >
              <p class="bold-title">
                Marketing
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
            </div>
            <div class="toolkit">
              <img
                src="~/assets/images/money-icon.svg"
                alt="temp image"
              >
              <p class="bold-title">
                Monetize
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="s7 container py-24">
      <div class="flex-container">
        <h2>The best <br>podcaster tools!</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed molestie ante enim, sit amet mollis orci posuere at. Aenean convallis a orci eget ultricies.</p>
      </div>
      <div class="article-container">
        <div class="article">
          <img
            src="~/assets/images/mulher-de-tiro-medio-com-fones-de-ouvido.png"
            alt="temp image"
          >
          <p class="date">
            Resource type | Feb, 2023
          </p>
          <h3>Optimize and monetize</h3>
          <div class="link-holder">
            <a href="#">Read now</a>
          </div>
        </div>
        <div class="article">
          <img
            src="~/assets/images/mulher-de-vista-lateral-se-preparando-para-podcast.png"
            alt="temp image"
          >
          <p class="date">
            Resource type | Feb, 2023
          </p>
          <h3>Hosting 101</h3>
          <div class="link-holder">
            <a href="#">Read now</a>
          </div>
        </div>
        <div class="article">
          <img
            src="~/assets/images/mulher-feliz-fazendo-podcast-de-tiro-medio.png"
            alt="temp image"
          >
          <p class="date">
            Resource type | Feb, 2023
          </p>
          <h3>Grow your audience</h3>
          <div class="link-holder">
            <a href="#">Read now</a>
          </div>
        </div>
      </div>
    </div>

    <div class="s8">
      <div class="container py-20">
        <div class="flex-container">
          <div class="image-holder">
            <img
              src="~/assets/images/group-8152.png"
              alt="temp image"
            >
          </div>
          <div class="email-holder">
            <h2>Signup Now</h2>
            <p>To get exclusive content updates via email</p>
            <form action="/action_page.php">
              <input
                id="email"
                type="email"
                name="email"
                placeholder="email address"
              >
              <input
                class="black-btn"
                type="submit"
              >
            </form>
          </div>
        </div>
      </div>
    </div>
    <VideoPreview model-value.title="this is a title" />
    <ArticlePreview />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHiddenQuality: true,
      isHiddenProduction: true,
      isHiddenHosting: true,
      isHiddenCoaching: true,
      isHiddenMarketing: true,
      isHiddenMonetize: true
    };
  }
};
</script>

<style>
.s1 {
  .flex-container {
    display: flex;
    margin-bottom: 6rem;
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;

    .even-space {
      flex-grow: 1;
      flex-basis: 0;
    }

    h1 {
      font-size: 60px;
      margin-bottom: 30px;
      line-height: 130%;
    }

    p {
      font-size: 20px;
      margin-bottom: 40px;
      line-height: 130%;
    }

    .black-btn {
      background-color: #000000;
      border: 1px solid #000000;
      color: white;
      padding: 16px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin-right: 20px;

      img {
        display: inline-block;
        margin-right: 0.5rem;
      }
    }

    .white-btn {
      border: 1px solid #000000;
      padding: 16px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
    }

    @media (width >= 1024px) {
      flex-direction: row;
      gap: 0;
      align-items: flex-end;
    }
  }
}

.s2 {
  background-color: #BA8BF8;

  .flex-container {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;

    .even-space {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;

      @media (width >= 1024px) {
        text-align: start;
      }

      .flex-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media (width >= 1024px) {
          justify-content: start;
        }
      }
    }

    .float-image {
      position: relative;

      img {
        @media (width >= 1024px) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -53%);
        }
      }
    }

    h2 {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .description {
      margin-bottom: 30px;
    }

    p {
      font-size: 16px;
      font-weight: bold;
    }

    .checkpoint {
      margin: 0 25px 0 15px;
    }

    @media (width >= 1024px) {
      flex-direction: row;
    }
  }
}

.s3 {
  h3 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 2rem;
  }

  .desktop {
    display: none;

    @media (width >= 1024px) {
        display: flex;
      }
  }

  .mobile {
    width: 100%;

    @media (width >= 1024px) {
        display: none;
      }
  }

  img {
    margin: auto;
  }

  .flex-container {
    display: flex;
  }
}

.s4 {
  .flex-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 2rem;

    .even-space {
      flex-grow: 1;
      flex-basis: 0;
    }

    h2 {
      font-size: 48px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      margin-bottom: 2rem;
    }

    .black-btn {
      background-color: #000000;
      border: 1px solid #000000;
      color: white;
      padding: 16px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin-right: 20px;
    }

    @media (width >= 1024px) {
      align-items: flex-end;
      flex-direction: row;
      gap: 0;
    }
  }
}

.s5 {
  .flex-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 3rem;

    .title-holder {
      flex-grow: 1;
      width: 100%;
      margin-right: 0;

      h2 {
        font-size: 48px;
        margin-bottom: 1rem;
      }

      p {
        font-size: 16px;
      }

      @media (width >= 1024px) {
        width: 50%;
        margin-right: -250px;
      }
    }

    .sound-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1rem;

      img {
        height: 8rem;

        @media (width >= 1024px) {
          height: 9rem;
        }
      }

      @media (width >= 1024px) {
        justify-content: flex-end;
      }
    }

    @media (width >= 1024px) {
      align-items: flex-end;
      flex-direction: row;
      gap: 0;
    }
  }
}

.s6 {
  background-color: #1E1827;
  color: #ffffff;

  .flex-container {
    display: flex;
    gap: 50px;
    flex-direction: column;

    h2 {
      font-size: 48px;
      line-height: 130%;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
    }

    .toolkit-flex-container {
      display: none;
      flex-wrap: wrap;
      column-gap: 21px;
      row-gap: 55px;

      .toolkit {
        flex-grow: 1;
        width: 30%;
      }

      .bold-title {
        font-size: 20px;
        margin: 15px 0;
      }

      @media (width >= 1024px) {
        display: flex;
      }
    }

    .toolkit-mobile-container {
      .toolkit {
        border-bottom: 1px solid #9C9C9C;
        padding-bottom: 1rem;

        .dropdown {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          img {
            width: 20px;
          }

          .title-holder {
            img {
              display: inline-block;
            }

            p {
              display: inline-block;
            }
          }
        }
      }

      .bold-title {
        font-size: 24px;
        margin: 1rem 0 1rem 3rem;
      }

      @media (width >= 1024px) {
        display: none;
      }
    }

    @media (width >= 1024px) {
      flex-direction: row;
    }
  }
}

.s7 {
  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;

    h2 {
      font-size: 48px;
      line-height: 130%;
      flex-grow: 1;
      width: 100%;

      @media (width >= 1024px) {
        width: 50%;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    @media (width >= 1024px) {
      align-items: flex-end;
      flex-direction: row;
      gap: 0;
      margin-bottom: 3rem;
    }
  }

  .article-container {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .article {
      border: 2px solid #1E1827;
      width: 100%;
      flex-grow: 1;

      img {
        width: 100%;
        height: auto;
      }

      .date {
        font-size: 12px;
        margin: 2rem 0 0 2rem;
      }

      h3 {
        font-size: 32px;
        margin: 0.5rem 0 1.5rem 2rem;
      }

      .link-holder {
        font-size: 20px;
        margin: 0 0 3.5rem 2rem;
        text-decoration: underline;
      }

      @media (width >= 1024px) {
        width: 33%;
      }
    }

    @media (width >= 1024px) {
      flex-direction: row;
    }
  }
}

.s8 {
  background-color: #00FFBD;

  .flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-holder {
      @media (width >= 1024px) {
        width: 33%;
      }
    }

    .email-holder {
      flex-grow: 1;
      text-align: center;

      @media (width >= 1024px) {
        text-align: left;
      }
    }

    h2 {
      font-size: 48px;
      line-height: 130%;
    }

    p {
      font-size: 16px;
      margin-bottom: 2rem;
    }

    #email {
      border: 1px solid #000000;
      padding: 16px 32px;
      margin-right: 1rem;
      margin-bottom: 1rem;
      width: 100%;

      @media (width >= 1024px) {
        width: 60%;
        margin-bottom: 0;
      }
    }

    ::placeholder {
      color: #000000;
      opacity: 1;
    }

    .black-btn {
      background-color: #000000;
      border: 1px solid #000000;
      color: white;
      padding: 16px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin-right: 20px;
      width: 100%;

      @media (width >= 1024px) {
        width: auto;
      }
    }

    @media (width >= 1024px) {
      flex-direction: row;
    }
  }
}
</style>
